import React from 'react';
import { Helmet } from 'react-helmet';

const PageNotFound = () => (
  <>
    <Helmet>
      <title>Page not found</title>
    </Helmet>
    <h1>Page not found</h1>
    <p>The requested page is unavailable.</p>
  </>
);

export default PageNotFound;
